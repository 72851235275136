import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import axiosHelper from "../helpers/axiosHelper";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import LiveAgenda from "../components/LiveAgenda";

import MiniPlayer from "../components/mini-player";
import MiniPlayerPlaceholder from "../components/mini-player";
import { CONTROLS, POSITION } from "../components/mini-player";
import liveStremLogo from "../img/btn_LiveStream.png";
import "./Live.css";

// const STREAM_PLAYBACK_URL =
//     "https://a359fdff542c.eu-west-1.playback.live-video.net/api/video/v1/eu-west-1.745088497094.channel.9iRrBYLTtRTj.m3u8";

const Live = () => {
  const { t } = useTranslation();
  const [playbackURL, setPlaybackURL] = useState<string>();
  const [currentEvent, setCurrentEvent] = useState<LiveEvent>();
  const [agendaEvent, setAgendaEvent] = useState<LiveEvent | null>();
  const [liveAvailable, setLiveAvailable] = useState<boolean>(
    axiosHelper.liveAvailable
  );

  useEffect(() => {
    console.log('LIVE MAIN EFFECT');

    axiosHelper.logWebAppEvent("Live", null, null);
    window.scrollTo(0, 0);
    return () => {
      console.log("LIVE MAIN CLOSE");
      setPlaybackURL(undefined);
    };

  }, []);

  const retrieveCurrentEvent = () => {
    axiosHelper.currentLiveEvent().then((theEvent) => {
      if (theEvent) {
        setCurrentEvent(theEvent);
        setAgendaEvent(theEvent);
        setLiveAvailable(true);
      } else {
        setLiveAvailable(false);
        setAgendaEvent(axiosHelper.nextLiveEvent);
        // console.log(axiosHelper.nextLiveEvent);
      }
    });
  };
  const liveAttempts = useRef(0);

  useEffect(() => {
    if (currentEvent) {
      if (t("LNG") === "en") {
        setPlaybackURL(currentEvent.streamingEN);
      } else {
        setPlaybackURL(currentEvent.streamingIT);
      }
    } else {
      retrieveCurrentEvent();
    }
  }, [currentEvent]);

  useEffect(() => {
    console.log('PLAYBACK EFFECT');

    if (currentEvent) {
      if (!playbackURL) {
        liveAttempts.current = liveAttempts.current + 1;
        console.log(liveAttempts.current);
        if (liveAttempts.current > 20) {
          setLiveAvailable(false);
        } else {
          if (t("LNG") === "en") {
            setTimeout(() => setPlaybackURL(currentEvent!.streamingEN), 10000);
          } else {
            setTimeout(() => setPlaybackURL(currentEvent!.streamingIT), 10000);
          }
        }
      } else {
        if (t("LNG") === "en") {
          setPlaybackURL(currentEvent!.streamingEN);
        } else {
          setPlaybackURL(currentEvent!.streamingIT);
        }
      }
    }
    return () => {
      console.log("cleanup");
    };
  }, [playbackURL]);

  useEffect(() => {
    console.log('LNG EFFECT');
    if (currentEvent) {
      if (playbackURL) {
        if (t("LNG") === "en") {
          setPlaybackURL(currentEvent!.streamingEN);
        } else {
          setPlaybackURL(currentEvent!.streamingIT);
        }
      }
    }
    return () => {
      console.log("cleanup");
    };
  }, [t("LNG")]);


  const endCallback = () => {
    console.log("ENDED ");
    setPlaybackURL(undefined);
  };
  return (
    <>
      <div className="page-wrapper page-live">
        <div className="page-container">
          <div className="page-content">
            <div className="top-area">
              <Navigation />
            </div>
            <div className="center-area">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-md-3 pt-3 live-agenda-wrapper">
                    {agendaEvent && <LiveAgenda day={3} />}
                  </div>

                  <div className="col-12 col-md-9">
                    {/* {liveAvailable && currentEvent && (
                      <>
                        {t("LNG") === "en" ? (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: currentEvent!.titleEN,
                            }}
                          />
                        ) : (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: currentEvent!.titleIT,
                            }}
                          />
                        )}
                      </>
                    )} */}

                    <div className="Live">
                      {liveAvailable ? (
                        <>
                          {playbackURL ? (
                            <MiniPlayer
                              streamUrl={playbackURL}
                              controls={[
                                CONTROLS.resize,
                                CONTROLS.close,
                                CONTROLS.mute,
                              ]}
                              position={POSITION.bottomRight}
                              onEnd={endCallback}
                              transition
                            />
                          ) : (
                            <div className="Live">
                              <div className="MiniPlayer">
                                <div className="MiniPlayer-videoBox">
                                  <div
                                    className="Placeholder"
                                    style={{ background: "rgb(0, 0, 0)" }}
                                  >
                                    <div className="Placeholder-content">
                                      <div
                                        className="Placeholder-spinner"
                                        style={{
                                          background: "rgb(255, 255, 255)",
                                        }}
                                      >
                                        <div
                                          className="Placeholder-gradient"
                                          style={{
                                            backgroundImage:
                                              "linear-gradient(0deg, rgb(0, 0, 0) 50%, rgba(0, 0, 0, 0.9) 100%), linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.6) 100%), linear-gradient(rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.3) 100%), linear-gradient(360deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%)",
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="MinPlayer-video"
                                    style={{
                                      transition: "none 0s ease 0s",
                                      inset: "auto 0px 0px auto",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "white",
                                        textAlign: "center",
                                        fontSize: "40px",
                                        lineHeight: "45px",
                                        marginTop: "50px",
                                      }}
                                    >
                                      {t("live.message2")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <img src={liveStremLogo} alt="Live Stream Logo" />
                          <h2>{t("live.message1")}</h2>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-area">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Live;
