import React from 'react';
import { BrowserRouter as Router } from "react-router-dom"

import Container from "./Container"

import './App.scss';

function App() {

  return (
    <Router>
      <Container />
    </Router>
  );
}

export default App;
