import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory } from "react-router-dom";
import axiosHelper from "../helpers/axiosHelper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

// Media

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

type LoginData = {
    email: string;
    password: string;
    rememberme: boolean;
};

const ExhibLogin = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [error, setError] = useState<boolean>(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState<string>();
    const [passwordShown, setPasswordShown] = useState(false);

    const { register, handleSubmit, errors } = useForm<LoginData>({
        criteriaMode: "all",
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };


    const onSubmitExhibitor = async (data: LoginData) => {
        try {
            const result = await axiosHelper.exhibitorLogin<UserAccount>(
                data.email,
                data.password
            );
            console.log(result);
            axiosHelper.setJntToken(result.token, data.rememberme);
            axiosHelper.saveUserProfile(result);
            history.push("/");
            // }
        } catch (error: any) {
            if (error.response == null) {
                setLoginErrorMessage("ERROR COMMUNICATING WITH BACKEND");
                setError(true);
            } else {
                switch (error.response.status) {
                    case 417:
                        setLoginErrorMessage(
                            "Troppi tentativi di login errati, l'account è stato disabilitato, riprovare dopo 10 minuti"
                        );
                        setError(true);
                        break;
                    case 401:
                        setLoginErrorMessage(t('login.wrong'));
                        setError(true);
                        break;
                    default:
                        setLoginErrorMessage(t('login.wrong'));
                        setError(true);
                        break;
                }
            }
        }
    };


    return (
        <div className="row px-2 p-md-0">
            <div className="col-12 login-box pt-3 pb-2 px-3">
                <form onSubmit={handleSubmit(onSubmitExhibitor)}>
                    <div className="row">
                        <div className="col-12">
                            <div className="text-body">
                                {t("login.username")}
                            </div>
                            <input
                                placeholder="e-mail"
                                name="email"
                                ref={register({
                                    required: t("login.mandatory") as string,
                                })}
                            />
                            <ErrorMessage errors={errors} name="codice20" />
                        </div>
                        <div className="col-12">
                            <div className="text-body">
                                {t("login.password")}
                            </div>
                            <div className="pass-wrapper">
                                <input
                                    type={"password"}
                                    placeholder="password"
                                    name="password"
                                    className="mb"
                                    ref={register({
                                        required: t("login.mandatory") as string,
                                    })}
                                />
                            </div>
                            <ErrorMessage errors={errors} name="email" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 d-flex justify-content-start align-items-center"></div>
                        <div className="col-md-3 d-flex justify-content-start align-items-center">
                            <div className="checkbox-row">
                                <input
                                    type="checkbox"
                                    placeholder="Ricordami"
                                    defaultChecked={true}
                                    name="rememberme"
                                    ref={register}
                                />
                                <label className="small">{t("login.remember")}</label>
                            </div>
                        </div>
                        <div className="col-md-3 d-flex justify-content-start align-items-center">
                            <button className="button--submit">LOGIN</button>
                        </div>
                    </div>
                    {error ? (
                        <div className="modal-text error-message">
                            {loginErrorMessage}
                        </div>
                    ) : null}
                </form>
            </div>
        </div>
    );
};

export default ExhibLogin;
