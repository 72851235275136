import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import axiosHelper from "../helpers/axiosHelper";
import { shortTimeString } from "../helpers/utils";

type Props = {
  day: number;
}
const LiveAgenda = (props: Props) => {
  const { day } = props;
  const { t } = useTranslation();
  const [liveEvents, setLiveEvents] = useState<LiveEvent[]>();
  const [currentDatLiveEvents, setCurrentDatLiveEvents] = useState<LiveEvent[]>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (liveEvents == null) {
      fetchAgenda();
    }
    return () => { };
  }, [liveEvents]);

  const fetchAgenda = async () => {
    try {
      const result = await axiosHelper.getAgenda();
      if (result != null) {
        // console.log(result);
        // console.log(day);
        setLiveEvents(result);
        setCurrentDatLiveEvents(result.filter((evt) => evt.day === day));
      }
    } catch {
      console.log("ERTROROR");
    }
  };

  return (
    <>
      <div className="live-agenda">
        <h2 className="live-agenda-title">{t(`agenda.day${day}.title`)}</h2>
        <div className="live-agenda-events scrollbar-light">
          {currentDatLiveEvents?.map((event) => (
            <div key={event.id} className="event ">
              <span>
                {shortTimeString(event.startDate)} -{" "}
                {shortTimeString(event.endDate)}
              </span>
              {t("LNG") === "en" && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: event.titleEN,
                  }}
                />
              )}
              {t("LNG") === "it" && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: event.titleIT,
                  }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default LiveAgenda;
