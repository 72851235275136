import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import axiosHelper from "../helpers/axiosHelper";
import Footer from "../components/Footer";
import logo from "../assets/img/IPN_PV21_1.png";
import Constants from "../helpers/constants";

const Hall = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [userProfile, setUserProfile] = useState<UserAccount>(
    axiosHelper.getUserAccount()!
  );
  const [eventDate, setEventDate] = useState<String>();
  const [imageClass, setImageClass] = useState("redefine");
  const [showVideo, setShowVideo] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  // Media

  useEffect(() => {
    axiosHelper.logWebAppEvent("Hall", null, null);

    // console.log('HALL USE EFFECT ' + axiosHelper.getDefaultEvent().vKey);
    // parseStatus(axiosHelper.getDefaultStatusForHall());
    // let mySubscription = getSubscription(
    //   "PANEL_" + axiosHelper.getDefaultEvent().vKey,
    //   (message: string) => {
    //     parseStatus(message);
    //   }
    // );

    if (userProfile != null) {
      // setEventDate(
      // longDateAndTimeString(axiosHelper.getDefaultEvent().fromDate)
      // );
    } else {
      setEventDate("venerdì 11 giugno 2021 - ore 10:00");
    }
    // if (!hasAutomatics()) {
    //     console.log('SUBSCRIBING AUTOMATICS');
    //     let koSubscription = getSubscription('KO_' + axiosHelper.getUserAccount()?.jntToken, (message: string) => {
    //         parseKoMessage(message)
    //     });

    //     let seppukuSubscription = getSubscription('SEPPUKU', (message: string) => {
    //         parseSpkMessage(message)
    //     });

    //     let refreshSubscription = getSubscription('REFRESH', (message: string) => {
    //         parseRefreshMessage(message)
    //     });
    //     setHasAutomatics(true);
    // } else {
    //     console.log('AUTOMATICS ALREADY SUBSCRIBED');
    // }
    window.scrollTo(0, 0);
    return () => {
      // console.log('HALL VIA EFFECT');
      // removeSubscription(mySubscription);
    };
  }, []);

  // const parseRefreshMessage = async (rfshMessage: string | null) => {
  //     if (rfshMessage != null) {
  //         var splitted = rfshMessage.split("|");

  //         let rfshId = splitted[0];
  //         let oldRfshId = localStorage.getItem('rfshId');
  //         if (rfshId != oldRfshId) {
  //             let seconds = parseInt(splitted[1]);
  //             let rndTimeout = Math.random() * 1000 * seconds;
  //             localStorage.setItem('rfshId', rfshId);
  //             const result = await axiosHelper.loginWithToken<UserProfile>();
  //             if (result.data !== null) {
  //                 axiosHelper.saveUserProfile(result.data);
  //                  console.log('SETTO LOGGED IN');
  //             }

  //         }
  //     }
  // }
  // const parseSpkMessage = (spkMessage: string | null) => {
  //     if (spkMessage != null) {
  //         var splitted = spkMessage.split("|");

  //         let seppId = splitted[0];
  //         let oldSeppId = localStorage.getItem('seppId');
  //         // console.log('SEPP ID ' + seppId);
  //         // console.log('OLD SEPP ID ' + oldSeppId);
  //         if (seppId != oldSeppId) {
  //             let seconds = parseInt(splitted[1]);
  //             let rndTimeout = Math.random() * 1000 * seconds;
  //             // console.log('Received Seppuku, reloading window in ' + rndTimeout);
  //             localStorage.setItem('seppId', seppId);
  //             setTimeout(() => { window.location.reload(); }, rndTimeout);

  //         }
  //     }
  // }

  // const parseStatus = (statusString: string | null) => {
  //   if (statusString !== null) {
  //     // axiosHelper.setCachedStatusForHall(statusString);
  //     var splitted = statusString.split("|");
  //     console.log(splitted);
  //     setImageClass(splitted[0]);
  //     setShowVideo(JSON.parse(splitted[1]));
  //     setShowCountdown(JSON.parse(splitted[2]));
  //     setTargetDateString(splitted[3]);
  //   }
  // };

  const saveUserProfile = async (data: UserAccount) => {
    try {
      // const result = await axiosHelper.updateAccount(data);
      // axiosHelper.saveUserProfile(result.data);
      // setUserProfile(result.data);
      // history.push("/welcome");
    } catch (error) {
      console.log(error);
    }
  };

  // const parseKoMessage = (koMessage: string | null) => {
  //     console.log('PARSE KO MESSAGE');
  //     console.log(koMessage);
  //     if (koMessage !== null) {
  //         // console.log('KICKOUT');
  //         if (koMessage == 'KO') {
  //             // console.log('KICKOUT disconnect');
  //             disconnectLS();
  //             // console.log('KICKOUT clear');
  //             axiosHelper.clearUserProfile()
  //             history.push('/ko');
  //         }
  //     }
  // }

  return (
    <div className="page-wrapper page-hall">
      <div className="page-container">
        <div className="page-content">
          <div className="center-area">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-8 col-lg-6 position-relative">
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center tww-container">
                      <img
                        className="tww-image"
                        src={logo}
                        alt="Issa Pulire logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row d-flex flex-row justify-content-between hall-btn-container">
                <div
                  className="col-12 col-md-4 text-center hall-btn hall-btn-primary"
                  onClick={() => {
                    history.push("/live");
                  }}
                >
                  {t("hall.live")}
                </div>
                <div
                  className="col-12 col-md-4 text-center hall-btn hall-btn-secondary"
                  onClick={() => {
                    history.push("/agenda");
                  }}
                >
                  {t("hall.events")}
                </div>
                {Constants.SHOW_EXHIBITORS &&
                  <div
                    className="col-12 col-md-4 text-center hall-btn hall-btn-primary"
                    onClick={() => {
                      window.location.replace('https://catalog.issapulire.com');
                    }}
                  >
                    {t("hall.exhibitors")}
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="bottom-area">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hall;
