import React, { useState, useEffect } from "react"
import axiosHelper from "../helpers/axiosHelper"
import Header from '../components/Header'
import Footer from '../components/Footer'
import Navigation from "../components/Navigation"
import ExhibCard from '../components/exhibitors/ExhibCard'
import ExhibDetail from '../components/exhibitors/ExhibDetail'
import { count } from "console"



const Exhibitors = () => {
    const [stands, setStands] = useState<Stand[]>();
    const [myStands, setMyStands] = useState<Stand[]>();
    const [selectedStand, setSelectedStand] = useState<Stand>();
    var countries: string[];

    useEffect(() => {
        console.log('##################');
        if (!stands) {
            fetchExhibitors();

        } console.log('##################');
        return () => {
            console.log('cleanup');
        }
    }, [stands]);


    const fetchExhibitors = async () => {
        try {
            const result = await axiosHelper.getExhibitors();
            if (result != null) {
                console.log(result);
                setStands(result);
                countries = [];
                result.forEach(item => {
                    let ctry = item.company.countryName;
                    if (countries.indexOf(ctry) < 0) {
                        countries.push(ctry);
                    }
                    countries.sort();
                })
            }
        } catch {
            console.log("ERTROROR");
        }

    }

    const selectExhibitor = (exhibitor: Stand) => {
        console.log(exhibitor);
        setSelectedStand(exhibitor);
    }
    return (<>
        <div className="page-wrapper page-login">
            <div className="page-container">
                <div className="page-content">
                    <div className="top-area">
                        <Navigation />
                    </div>
                    <div className="center-area">
                        <div className="row">
                            <div className="col-4">
                                {stands?.map((stand, index) => (<ExhibCard key={index} stand={stand} callback={() => selectExhibitor(stand)} />))}
                            </div>
                            <div className="col-8">
                                {selectedStand && <ExhibDetail stand={selectedStand} />}
                            </div>
                        </div>
                    </div>
                    <div className="bottom-area">
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default Exhibitors
