import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axiosHelper from "../helpers/axiosHelper";
import { longDateAndTimeString } from "../helpers/utils";
import Header from "../components/Header";
import Footer from "../components/Footer";
import * as Leaflet from "leaflet";

const PulireMap = () => {
  let map;
  const history = useHistory();
  const [eventDate, setEventDate] = useState<String>('date');
  const [imageClass, setImageClass] = useState("redefine");
  const [showVideo, setShowVideo] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);
  const [targetDateString, setTargetDateString] = useState(
    "2020-12-01T10:30:00.000Z"
  );
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  useEffect(() => {
    axiosHelper.logWebAppEvent("PulireMap", null, null);
    loadMap();
    window.scrollTo(0, 0);
    return () => {
      // console.log('HALL VIA EFFECT');
      // removeSubscription(mySubscription);
    };
  }, []);

  const loadMap = () => {
    map = Leaflet
      .map('map', {
        attributionControl: false,
        zoomControl: false
      })
    // .on("click", this.onMapClicked.bind(this))
    // .on("zoomend", this.onMapZoomed.bind(this))
    // .on("moveend", this.onMapMoved.bind(this));

    // this.zoom = this.zoomLevel();
    // this.setMaxBounds();
    Leaflet.tileLayer('./assets/tiles/{z}/{x}/{y}.png', {
      noWrap: true,
      attribution: '',
      maxZoom: 5,
      detectRetina: true,
      // continuousWorld: false,
      // zoomSnap: 0.5,
      // zoomDelta: 0.5
    }).addTo(map);
    // this.myIcon = Leaflet.icon({
    //   iconUrl: './assets/img/map_pin.png',
    //   iconSize: [30, 36],
    //   iconAnchor: [15, 34],
    //   popupAnchor: [2, -32]
    // });
    // this._latLng = this.unproject([this.width / 2, this.height / 2]);
    // this.map.setView(this.latLng, 2);
    // this.firstRow = '';
    // this.secondRow = '';

    // if (this.stand) {
    //   console.log('partp');
    //   this._latLng = this.unproject([this.stand.x, this.stand.y]);
    //   temp = this;

    //   setTimeout(this.showStand, 500);
    //   setTimeout(this.zoomIn1, 800);
    //   setTimeout(temp.zoomIn2, 1200);

    // } else {


    // }
  }


  return (
    <>
      <div className="page-wrapper page-login">
        <div className="page-container">
          <div className="page-content">
            <div className="top-area">
              <Header />
            </div>
            <div className="center-area">
              <div className="map-container">
                <div id="map">
                </div>
              </div>

            </div>
            <div className="bottom-area">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>);
};

export default PulireMap;
