import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory } from "react-router-dom";
import axiosHelper from "../../helpers/axiosHelper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";


type Props = {
    stand: Stand;
    callback: ()=>void;
};

const ExhibCard = (props:Props) => {
    const {stand, callback}= props;
    const { t, i18n } = useTranslation();
    const history = useHistory();

    return (
        <div className="card" onClick={callback}>
          <div className="padhall">
            {t("LABEL_HALL_SHORT")} <span className="hall">{stand.pavilion} </span> | {t('LABEL_STAND')} <span className="stand">{stand.stand}</span>
          </div>
          <div className="name">{stand.company.exhibitorName}</div>
          <div className="country"><span className="country">{stand.company.countryName}</span></div>
        </div>    );
};

export default ExhibCard;
