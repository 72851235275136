import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axiosHelper from "../helpers/axiosHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import VisitorLogin from '../components/VisitorLogin';
import ExhibLogin from '../components/ExhibLogin';

// Media
import logo from "../assets/img/IPN_PV21_1.png";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

type LoginData = {
  codice20: string;
  email: string;
  username: string;
  password: string;
  rememberme: boolean;
};

const Login = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [error, setError] = useState<boolean>(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const [showExhibitorLogin, setShowExhibitorLogin] = useState(false);
  const [showVisitorLogin, setShowVisitorLogin] = useState(true);

  const { register, handleSubmit, errors } = useForm<LoginData>({
    criteriaMode: "all",
  });

  useEffect(() => {
    axiosHelper.logWebAppEvent("Login", null, null);

    window.scrollTo(0, 0);
  }, []);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const selectVisitorLogin = () => {
    setShowVisitorLogin(true);
    setShowExhibitorLogin(false);
  };

  const selectExhibitorLogin = () => {
    setShowExhibitorLogin(true);
    setShowVisitorLogin(false);
  };





  // if (storageHelper.userIsLogged()) {
  //     history.push("/");
  // }
  return (
    <div className="page-wrapper page-login">
      <div className="page-container">
        <div className="page-content">
          <div className="top-area">
            <Header />
          </div>
          <div className="center-area">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-8 col-lg-6 position-relative">
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center tww-container">
                      <img
                        className="tww-image"
                        src={logo}
                        alt="Issa Pulire logo"
                      />
                    </div>
                  </div>

                  <div className="row px-2 p-md-0">
                    <div className="col-12 ">
                      <div className="row login-tabs">
                        <div
                          className={`col-6 col-xs-12 pt-3 pb-2 px-3 ${showVisitorLogin ? "active" : ""
                            }`}
                          onClick={() => selectVisitorLogin()}
                        >
                          <h2 className="text-title pb-2">
                            {t("login.visitorLogin")}
                          </h2>
                        </div>
                        <div
                          className={`col-6 col-xs-12 pt-3 pb-2 px-3 ${showExhibitorLogin ? "active" : ""
                            }`}
                          onClick={() => selectExhibitorLogin()}
                        >
                          <h2 className="text-title pb-2">
                            {t("login.exhibitorLogin")}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>

                  {showVisitorLogin && <VisitorLogin />}


                  {showExhibitorLogin && <ExhibLogin />}
                
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-area">
            <Footer />
          </div>
        </div>
      </div>
    </div >
  );
};

export default Login;
