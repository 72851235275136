import React, { useState, useEffect } from "react"
import axiosHelper from "../helpers/axiosHelper"

// Media
import logoTS from "../assets/logo-ts-color.svg"
import logoEvent15 from "../assets/logo_event/logo_event_color_15.svg"

const Footer = () => {
  const [userProfile, setUserProfile] = useState<UserAccount>(
    axiosHelper.getUserAccount()!
  )

  return (
    <div className="container-fluid white-bg">
      {/* <div className="row">
      </div>
      <div className="row pt-2 pb-2 white text-uppercase fw-bolder px-0">
        <div className="col-12 d-flex align-items-center justify-content-center py-2">
          <div className="d-none d-lg-block">
            <img src={logoTS} width="143px" alt="TeamSystem" />
          </div>
          <div className="d-block d-lg-none">
            {userProfile == null ? (
              <img src={logoEvent15} height="28px" alt="TeamSystem" />
            ) : (null

            )}
          </div>
        </div>
      </div> */} 
    </div>
  )
}

export default Footer
