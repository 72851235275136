import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import enIcon from "../assets/flags/en.gif";
import itIcon from "../assets/flags/it.gif";

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const handleLanguageChange = (selected: any) => {
    i18n.changeLanguage(selected.value);
    setSelectedLanguage(selected);
  };

  const languageOptions = [
    { value: "en", label: "ENGLISH", icon: enIcon },
    { value: "it", label: "ITALIANO", icon: itIcon },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(
    languageOptions.find((item) => item.value === t("LNG")) ||
      languageOptions[2]
  );

  return (
    <>
      {languageOptions.map((item: any, index: any) => (
        <span
          key={index}
          className="btn btn-link"
          onClick={() => {
            handleLanguageChange(item);
          }}
        >
          <img src={item.icon} width="19" height="13" alt="" />
        </span>
      ))}
    </>
  );
};

export default LanguageSwitcher;
