import React, { useState, useEffect } from "react";
import {
    Switch,
    Route,
    withRouter,
} from 'react-router-dom';

import axiosHelper from './helpers/axiosHelper';

// import Activate from './screens/Activate';
import Error404Page from './screens/Error404Page';
import Logout from './screens/Logout';
import Hall from './screens/Hall';
import Login from './screens/Login';
import Live from './screens/Live';
import Agenda from './screens/Agenda';
import PulireMap from './screens/PulireMap';
import Exhibitors from './screens/Exhibitors';


const Container = ({ location }: any) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

    useEffect(() => {
        console.log('wlf2');
        // console.log('USE EFFECT DI CONTAINER');
        const retrieveUserProfile = async () => {
            try {
                const result = await axiosHelper.loginWithToken<UserAccount>();
                if (result.data !== null) {
                    axiosHelper.saveUserProfile(result.data);
                    // console.log('SETTO LOGGED IN');
                    setIsLoggedIn(true);
                } else {
                    // console.log('LOGN ERR ');
                    setIsLoggedIn(false);
                }

            } catch (e) {
                console.log(e);
                setIsLoggedIn(false);
            }
        }
        if (axiosHelper.isLoggedIn) {
            // console.log('LOGGATO IN');
            setIsLoggedIn(true);
        } else if (axiosHelper.hasToken) {
            // console.log('HA IL TOKEN RETRIEVO');
            retrieveUserProfile()
        } else {
            // console.log('NON HO IL TOKEN NE SONO LOGGATO');
            setIsLoggedIn(false);
        }
        return () => {


        }
    })

    if (isLoggedIn == null) {
        return <div />
    } else {
        return (
            <Switch>
                {/* <Route exact path="/ko" component={KickedOut} />
                <Route exact path='/activate/:token' component={Activate} />
                <Route exact path='/resetpass/:token' component={Activate} /> */}
                <Route exact path='/live' component={Live} />
                <Route exact path='/login' component={Login} />
                <Route exact path='/agenda' component={Agenda} />
                <Route exact path='/map' component={PulireMap} />
                <Route exact path='/exhibitors' component={Exhibitors} />
                <Route exact path='/logout' component={Logout} />

                {/* <Route exact path='/comingsoon' component={ComingSoon} />
                <Route exact path='/mobile' component={MobileBlock} />
                <Route exact path='/passwordreset' component={PasswordReset} />
                <Route exact path='/Privacy' component={Privacy} /> */}
                {isLoggedIn ? (<>
                    <Route exact path='/' component={Hall} />
                    <Route exact path='/hall' component={Hall} />

                    {/* <Route exact path='/thankyou' component={ThankYou} />
                    <Route exact path='/hall' component={Hall} />
                    <Route exact path='/welcome' component={Hall} />
                    <Route exact path='/logout' component={Logout} />
                    <Route exact path='/agenda' component={AgendaNew} />
                    <Route exact path='/helpdesk' component={InfoDesk} />
                    <Route exact path='/profile' component={Profile} />
                    <Route exact path='/plenary' component={Plenary} />
                    <Route exact path='/guest' component={GuestPage} /> */}
                </>) : (<>
                    <Route exact path='/' component={Hall} />
                    {/* <Route exact path='/thankyou' component={Login} />
                    <Route exact path='/hall' component={Login} />
                    <Route exact path='/agenda' component={Login} />
                    <Route exact path='/helpdesk' component={Login} />
                    <Route exact path='/profile' component={Login} />
                    <Route exact path='/plenary' component={Login} />
                    <Route exact path='/guest' component={Login} /> */}
                </>)}
                <Route path='/*' component={Error404Page} />
            </Switch>
        );
    }
};


export default withRouter(Container);
