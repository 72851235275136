import { Link } from "react-router-dom";
import LanguageSwitcher from "../components/LanguageSwitcher";
// Media
import logo from "../assets/img/logo_IPV21_nodata@2x.png";

const Header = () => {
  return (
    <div className="container">
      <div className="row pt-2 pb-2 white top-header">
        <div className="col-6 d-flex align-items-center justify-content-start">
          <Link to="/">
            <img src={logo} width="160px" alt="Issa Pulire 2021" />
          </Link>
        </div>
        <div className="col-6 d-flex align-items-center justify-content-end">
          <LanguageSwitcher />
        </div>
      </div>
    </div>
  );
};

export default Header;
