import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Constants from "../helpers/constants";

import Header from "../components/Header";

// Media
import iconClose from "../img/close-button.svg";
import iconOpen from "../img/open-button.svg";

//Link icons
import iconAgenda from "../img/icon-help.svg";
import iconAgendaHover from "../img/icon-help.svg";

import axiosHelper from "../helpers/axiosHelper";

interface NavItemProps {
  routeName: string;
  route: string;
  iconSrc: string;
  iconHoverSrc: string;
}

const Navigation = () => {
  const { t, i18n } = useTranslation();

  const [userProfile, setUserProfile] = useState<UserAccount>(
    axiosHelper.getUserProfile()!
  );


  const [menusDisabled, setMenusDisabled] = useState(false);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // document.addEventListener("wolf", (evt) => {
  //   console.log(evt); //FIXME unused
  //   setMenusDisabled(true);
  // });

  useEffect(() => {
    // console.log(pathname);
    var interval: any;
    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleNavToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };

  const renderStaticNavigationToggle = (opened: boolean) => {
    if (!menusDisabled) {
      // if (!userProfile.firstLogin) {
      return (
        <div
          className="navigation-toggle"
          onClick={(event) => handleNavToggle(event)}
        >
          {opened ? (
            <img src={iconClose} className="nav-toggle-close" alt="close" />
          ) : (
            <img src={iconOpen} className="nav-toggle-open" alt="open" />
          )}
        </div>
      );
      // }
    }
  };

  const NavItem = (props: NavItemProps) => {
    const { iconSrc, iconHoverSrc, route, routeName } = props;
    const handleNavigation = (route: string) => {
      if (route === '/exhibitors') {
        exhibitorsClicked();
      } else {
        history.push(route);
      }
    }
    return (
      <div
        className="nav-item-container pb-4"
        onClick={() => handleNavigation(route)}
      >
        <div className="nav-item-handle large ps-4">{routeName}</div>
      </div>
    );
  };

  const pathname = window.location.pathname;
  const exhibitorsClicked = () => {
    window.location.replace('https://catalog.issapulire.com');
  }
  const NavHeader = () => {
    return (
      <Fragment>
        {/* <div className="d-none d-lg-block "> */}
        <div className="">
          <Header />
        </div>
        <div className="header">
          <div className="container">
            <div className="row">
              <div className="col-12 nav-utils py-1 pb-0">
                <div className="row d-flex justify-content-between align-items-center">
                  {/* Mobile */}
                  <div className="col-6 nav-left d-block d-lg-none pt-2">
                    {/* <Link to="/hall">LOGO</Link> */}
                  </div>
                  {!menusDisabled && (
                    <>
                      {/* Desktop */}
                      <div className="col-6 nav-left d-none d-lg-block">
                        {axiosHelper.isLoggedIn && <>
                          <div className="primary small thin">Ciao,</div>
                          <div className="userprofile-name d-inline">
                            {userProfile.nome} {userProfile.cognome}
                          </div>
                        </>}
                      </div>
                      <div className="col-6 nav-right d-none d-lg-flex align-items-center justify-content-end pe-0">
                        <Link to="/" className="nav-link">
                          {t("menu.home")}
                        </Link>
                        <Link to="/live" className="nav-link">
                          {t("menu.live")}
                        </Link>
                        <Link to="/agenda" className="nav-link">
                          {t("menu.events")}
                        </Link>
                        {Constants.SHOW_EXHIBITORS &&
                          <a className="nav-link" onClick={() => exhibitorsClicked()}>
                            {t("menu.exhibitors")}
                          </a>}
                        {axiosHelper.isLoggedIn ?
                          <Link to="/logout" className="nav-link">
                            {t("menu.logout")}
                          </Link>
                          :
                          <Link to="/login" className="nav-link">
                            {t("menu.login")}
                          </Link>}
                      </div>
                      <div className="col-6 nav-right d-flex d-lg-none align-items-center justify-content-end pe-0">
                        {!menusDisabled && (
                          <>{renderStaticNavigationToggle(false)}</>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <>
      <NavHeader />
      <div className={`navigation-wrapper ${isOpen ? "open" : "close"}`}>
        <div className="navigation-container d-flex flex-column">
          <div className="nav-logo d-flex flex-row justify-content-end pe-4">
            {renderStaticNavigationToggle(true)}
          </div>
          <div className="mobile-header ps-3 pb-5">
            {/* <div className="primary small thin">Ciao,</div>
            <div className="userprofile-name d-inline large fw-bold pe-2">
              Nome Cognome
            </div>
            <Link to="/logout" className="logout small">
              Esci
            </Link> */}
          </div>

          <div className="nav-navigation pt-2 flex-grow-1">
            <NavItem
              routeName={t("menu.home")}
              route="/"
              iconSrc={iconAgenda}
              iconHoverSrc={iconAgendaHover}
            />
            <NavItem
              routeName={t("menu.live")}
              route="/live"
              iconSrc={iconAgenda}
              iconHoverSrc={iconAgendaHover}
            />
            <NavItem
              routeName={t("menu.events")}
              route="/agenda"
              iconSrc={iconAgenda}
              iconHoverSrc={iconAgendaHover}
            />
            <NavItem
              routeName={t("menu.exhibitors")}
              route="/exhibitors"
              iconSrc={iconAgenda}
              iconHoverSrc={iconAgendaHover}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigation;
