import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navigation from "../components/Navigation";
import VideoModal from "../components/VideoModal";

import axiosHelper from "../helpers/axiosHelper";
import { shortTimeString } from "../helpers/utils";
import playIcon from "../img/play_icon.png";

const Agenda = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [liveEvents, setLiveEvents] = useState<LiveEvent[]>();
  const [filteredLiveEvents, setFilteredLiveEvents] = useState<LiveEvent[]>();
  const [currentDay, setCurrentDay] = useState<number>(1);
  const [currentVideo, setCurrentVideo] = useState<LiveEvent | null>(null);
  const [playervisible, setPlayerVisible] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (liveEvents != null) {
      // console.log("NON SERVE");
    } else {
      // console.log("FETCHO");
      fetchAgenda();
    }
    return () => {};
  }, [liveEvents]);

  const fetchAgenda = async () => {
    try {
      const result = await axiosHelper.getAgenda();
      if (result != null) {
        // console.log(result);
        setLiveEvents(result);
        // TODO x WOLF (selezionare il giorno giusto)
        setFilteredLiveEvents(result.filter((evt) => evt.day === 1));
      }
    } catch {
      // console.log("ERTROROR");
    }
  };

  const changeDay = (d: number) => {
    setCurrentDay(d);
    setFilteredLiveEvents(liveEvents!.filter((evt) => evt.day === d));
  };

  useEffect(() => {
    if (currentVideo != null) {
      // console.log("setto visible");
      setPlayerVisible(true);
    } else {
      // console.log("setto invisible ANZI NO");
      // setPlayerVisible(false);
    }
  }, [currentVideo]);

  useEffect(() => {
    if (!playervisible) {
      // console.log("NON VISIBILE");
      setCurrentVideo(null);
    } else {
      // console.log("VISIBILE NON FACCIO NULLA");
    }
  }, [playervisible]);

  const handleVideoPlay = (video: LiveEvent) => {
    // console.log('APRO VIDEO');
    // console.log(JSON.stringify(video))
    // console.log(video);
    axiosHelper.logWebAppEvent("FollowUp", video.id, "PLAY");
    setCurrentVideo(video);
    // setPlayerVisible(true);
  };

  const handleVideoClose = () => {
    // console.log('CHIUDO VIDEO');
    axiosHelper.logWebAppEvent("FollowUp", currentVideo!.id, "CLOSE");
    // setCurrentVideo(null);
    setPlayerVisible(false);
  };

  return (
    <div className="page-wrapper page-agenda">
      <div className="page-container">
        <div className="page-content">
          <div className="top-area">
            <Navigation />
          </div>
          <div className="center-area">
            <div className="container-fluid">
              <div className="row">
                <div
                  className={`col-4 day ${currentDay === 1 ? "selected" : ""}`}
                  onClick={() => {
                    changeDay(1);
                  }}
                >
                  {t("agenda.day1.title")}
                </div>
                <div
                  className={`col-4 day ${currentDay === 2 ? "selected" : ""}`}
                  onClick={() => {
                    changeDay(2);
                  }}
                >
                  {t("agenda.day2.title")}
                </div>
                <div
                  className={`col-4 day ${currentDay === 3 ? "selected" : ""}`}
                  onClick={() => {
                    changeDay(3);
                  }}
                >
                  {t("agenda.day3.title")}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {filteredLiveEvents?.map((event) => (
                    <div className="row event" key={event.id}>
                      <div
                        className={`col-12 col-lg-2 event-category cat${event.liveEventCategory.id}`}
                      >
                        <p>
                          <span>
                            {shortTimeString(event.startDate)} -{" "}
                            {shortTimeString(event.endDate)}
                          </span>
                        </p>
                        <p>{event.liveEventCategory.nameEN}</p>
                        {event.vodIT && (
                          <img
                            src={playIcon}
                            alt="play"
                            onClick={() => handleVideoPlay(event)}
                          />
                        )}
                      </div>
                      <div className="col-12 col-lg-4 event-title">
                        {t("LNG") === "en" && (
                          <>
                            {event.titleEN && (
                              <p>
                                <span>{t("agenda.item.title")}</span>
                              </p>
                            )}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: event.titleEN,
                              }}
                            />
                          </>
                        )}
                        {t("LNG") === "it" && (
                          <>
                            {event.titleIT && (
                              <p>
                                <span>{t("agenda.item.title")}</span>
                              </p>
                            )}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: event.titleIT,
                              }}
                            />
                          </>
                        )}
                      </div>

                      <div className="col-12 col-lg-4 event-speaker">
                        {t("LNG") === "en" && (
                          <>
                            {event.speakersEN && (
                              <p>
                                <span>{t("agenda.item.speaker")}</span>
                              </p>
                            )}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: event.speakersEN,
                              }}
                            />
                          </>
                        )}
                        {t("LNG") === "it" && (
                          <>
                            {event.speakersIT && (
                              <p>
                                <span>{t("agenda.item.speaker")}</span>
                              </p>
                            )}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: event.speakersIT,
                              }}
                            />
                          </>
                        )}
                      </div>
                      <div className="col-12 col-lg-2 event-organization">
                        {event.organizer && (
                          <p>
                            <span>{t("agenda.item.organization")}</span>
                          </p>
                        )}
                        <p>{event.organizer}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-area">
            <Footer />
          </div>
        </div>
      </div>

      {playervisible ? (
        <VideoModal
          videoUrl={
            t("LNG") === "it" ? currentVideo!.vodIT : currentVideo!.vodEN
          }
          visible={playervisible}
          onClose={handleVideoClose}
        />
      ) : null}
    </div>
  );
};

export default Agenda;
