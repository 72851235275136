import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import axiosHelper from '../helpers/axiosHelper';

const Logout = () => {
  const history = useHistory();



  useEffect(() => {
    const sendLogoutEvent = async () => {
      try {
        await axiosHelper.logWebAppEvent('Logout', null, null);

      } catch (e) {
        console.error(e);
      } finally {
        axiosHelper.clearUserProfile()
        history.push('/');
      }
    }
    sendLogoutEvent();

    console.log('LOGGING OUT');
    // performLogout();
    window.scrollTo(0, 0);
  }, [history]);

  return <></>
};

export default Logout;
