import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory } from "react-router-dom";
import axiosHelper from "../../helpers/axiosHelper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";


type Props = {
    stand: Stand;
    callback?: () => void;
};

const ExhibCard = (props: Props) => {
    const { stand } = props;
    const { t, i18n } = useTranslation();
    const history = useHistory();


    const emailPressed = () => { console.log('email pressed') }
    const websitePressed = () => { console.log('email pressed') }
    const phonePressed = () => { console.log('email pressed') }

    return (<>
        <div className="padhall">
            {t('LABEL_HALL_SHORT')} <span className="hall"> {stand.pavilion} </span> | {t('LABEL_STAND')} <span className="stand">{stand.stand}</span>
        </div>
        <h2>{stand.company.exhibitorName}</h2>
        <div>
            <div className="row">
                <div className="col-9">
                    <div className="logo">
                        {stand.company.logoURL != null ?
                            <img src={stand.company.logoURL} />
                            :
                            <div style={{ padding: '30px 10px' }}>No image</div>
                        }

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-9">
                    <div className="info">
                        <h4>{t('LABEL_ADDRESS')}</h4>
                        <div>
                            {stand.company.address}<br /> {stand.company.zipCode} {stand.company.city} {stand.company.province}<br /> {stand.company.countryName}
                        </div>
                        <h4>Email</h4>
                        <div onClick={() => emailPressed} >{stand.company.email}</div>
                        <h4>Web</h4>
                        <div onClick={() => websitePressed} >{stand.company.website}</div>
                        <h4>{t('LABEL_TEL')}</h4>
                        <div onClick={() => phonePressed} >{stand.company.phone}</div>
                        <h4>Social</h4>
                        {/* <div><span *ngFor="let social of socialNetworks"><img src="{{social.appURL}}" (click)="openSocialUrl(social)"></span></div> */}
                </div >
            </div >
            <div className="col-3">

            </div>
        </div >
    </div >
      <hr/>
      <div className="area" text-wrap>
        <h4>{t('LABEL_DESCRIPTION')}</h4>
        <div>{stand.company.textDescription}</div>
      </div>
      <hr/>
      <div className="area brands">
        <h4>{t('LABEL_BRANDS')}</h4>
        {/* <p *ngFor="let brand of stand.company.brands">{{brand.name}}</p> */}
      </div >
      <div className="clear"></div>
      <hr/>
      </>
        );
};

export default ExhibCard;
