import axios from "axios";
import Constants from "./constants";
import { isOlderThanSeconds } from './utils';
import { generateUniqueID } from "./utils";

const AUTH_TOKEN = "authToken";
const UNIQUE_ID = "uniqueID";
interface WebAppLog {
    pageName: string,
    idLibraryContent: number,
    event: string
}

class AxiosHelper {
    isLoggedIn = false;
    hasToken = false;
    isExhibitor = false;
    isVisitor = false;
    liveAvailable = true;
    nextLiveEvent: LiveEvent | null = null;

    savePermanently = false;
    profileCache: UserAccount | null = null;


    constructor() {
        this.getUniqueID();
        let token = localStorage.getItem(AUTH_TOKEN);
        if (token !== null) {
            this.setJntToken(token, true);
            // this.loginWithToken();
            // this.isLoggedIn = true;
        }
        this.getUniqueRecord();
    }

    visitorLogin<UserAccount>(codice20: string, email: string) {
        let url = Constants.API_ENDPOINT + '/ra/Visitatore/login';
        var bodyFormData = new FormData();
        bodyFormData.append("c", codice20);
        bodyFormData.append("e", email);

        return axios.post(url, bodyFormData)
            .then((response) => {
                console.log(response);
                return response.data;
            }).catch((error) => {
                throw error;
            })
    }

    exhibitorLogin<UserAccount>(email: string, password: string) {
        let url = Constants.API_ENDPOINT + '/ra/User/login';
        return axios.get(url + '?email=' + email + '&password=' + password)
            .then((response) => {
                console.log(response);
                return response.data;
            }).catch((error) => {
                throw error;
            })
    }

    getUniqueRecord() {
        return axios.get<UniqueRecord[]>(Constants.API_ENDPOINT + '/ra/UniqueRecord').then((response) => {
            console.log(response);
            this.liveAvailable = response.data[0].liveEnabled;
            return response.data;
        }).catch((error) => {
            throw error;
        })
    }

    loginWithToken<UserAccount>() {
        if (axios.defaults.headers.common["authToken"]) {
            return axios.get<UserAccount>(`${Constants.API_ENDPOINT}/ra/Visitatore/tokLogin`)
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    // if (error.response.status === 401) {
                    this.clearUserProfile();
                    // }
                    throw error;
                });
        } else {
            throw "no token";
        }
    }
    setUserAccount(ua: UserAccount) {
        localStorage.set('userAccount', ua);
        this.setJntToken(ua.authToken, false);
        if (ua.type === "V") {
            this.isVisitor = true;
            this.isExhibitor = false;
        } else if (ua.type === 'E') {
            this.isExhibitor = true;
            this.isVisitor = false;
        }
        // this.events.publish('phoneHome');
    }

    getUserAccount(): UserAccount | null {
        if (this.profileCache !== null) {
            return this.profileCache;
        }
        return null;
    }

    setJntToken(token: string, permanently: boolean) {
        this.hasToken = true;
        axios.defaults.headers.common["authToken"] = token;
        if (permanently) {
            localStorage.setItem(AUTH_TOKEN, token);
        }
    }
    setClientId(id: string) {
        axios.defaults.headers.common["clientId"] = id;
    }

    saveUserAccount(data: UserAccount) {
        // console.log('saveUserProfile');
        // console.log(data);
        if (data !== null) {
            this.isLoggedIn = true;
        }
        this.profileCache = data;

    }

    clearUserProfile() {
        // localStorage.clear();
        localStorage.removeItem(AUTH_TOKEN);

        this.isLoggedIn = false;
        this.profileCache = null;
        this.savePermanently = false;
    }


    getUserProfile(): UserAccount | null {
        // console.log("##########################GET USER PROFILE");
        if (this.profileCache !== null) {
            return this.profileCache;
        }
        return null;
    }


    saveUserProfile(data: UserAccount) {
        // console.log('saveUserProfile');
        // console.log(data);
        if (data !== null) {
            this.isLoggedIn = true;
        }
        this.profileCache = data;

    }

    clearUserAccount() {
        localStorage.removeItem(AUTH_TOKEN);

        this.isLoggedIn = false;
        this.profileCache = null;
        this.savePermanently = false;
    }

    getUniqueID(): string {
        let result = localStorage.getItem(UNIQUE_ID);
        if (result !== null) {
            this.setClientId(result);
            return result;
        } else {
            const id = generateUniqueID("jnt-");
            this.saveUniqueID(id);
            return id;
        }
    }
    saveUniqueID(id: string) {
        localStorage.setItem(UNIQUE_ID, id);
        this.setClientId(id);
    }

    logWebAppEvent(
        pageName: string | null,
        idLibraryContent: number | null,
        event: string | null
    ) {
        return axios.post(`${Constants.API_ENDPOINT}/ra/WebAppLog`, {
            pageName,
            idLibraryContent,
            event,
        });
    }

    isLiveEnabled() {
        return this.liveAvailable;
    }


    currentLiveEvent() {
        const fetchAgenda = async () => {
            try {
                const result = await this.getAgenda();
                if (result != null) {
                    this.liveAvailable = false;
                    let eventIndex = -1;
                    const now = new Date();
                    //FIXME
                    // const now = new Date(Date.parse('2021-09-07T08:30:00+02:00'.replace('+02:00', '.000Z')))
                    for (let index = 0; index < result.length; index++) {
                        const liveEvent = result[index];
                        this.nextLiveEvent = null;
                        const startDate = new Date(Date.parse(liveEvent.startDate.replace('+02:00', '.000Z')));
                        const endDate = new Date(Date.parse(liveEvent.endDate.replace('+02:00', '.000Z')));
                        if (now.getTime() < startDate.getTime()) {
                            console.log('event in the future')
                            if (this.nextLiveEvent == null) {
                                this.nextLiveEvent = liveEvent
                            }
                        } else {
                            console.log('EVENT MIGHT BE LIVE')
                            if (endDate.getTime() > now.getTime()) {
                                console.log('event is live');
                                this.liveAvailable = true;
                                eventIndex = index;
                                break;
                            } else {
                                console.log('event is not live');
                            }
                        }
                    }

                    // console.log(result);
                    if (eventIndex < 0) {
                        console.log('returning null');
                        return null;
                    }
                    return result[eventIndex];
                }
            } catch {
                console.log("ERTROROR");
            }
        };
        return fetchAgenda();
    }

    hasLiveEventCache = false;
    liveEventCache: LiveEvent[] = [];
    liveEventCacheDate = new Date();
    getAgenda() {
        if (this.hasLiveEventCache && !isOlderThanSeconds(this.liveEventCacheDate, 30)) {
            console.log('using cache')
            return Promise.resolve(this.liveEventCache);
        } else {
            console.log('fetching')
            return axios.get<LiveEvent[]>(`${Constants.API_ENDPOINT}/ra/LiveEvent`)
                .then((response) => {
                    this.liveEventCache = response.data;
                    this.hasLiveEventCache = true;
                    this.liveEventCacheDate = new Date();
                    return response.data;
                })
                .catch((err) => {
                    throw err;
                });
        }
    }

    hasExhibitorsCache = false;
    exhibitorsCache: Stand[] = [];
    exhibitorsCacheDate = new Date();
    getExhibitors() {
        if (this.hasExhibitorsCache && !isOlderThanSeconds(this.exhibitorsCacheDate, 600)) {
            return Promise.resolve(this.exhibitorsCache);
        } else {
            return axios.get<Stand[]>(`${Constants.API_ENDPOINT}/ra/Stand`)
                .then((response) => {
                    this.exhibitorsCache = response.data;
                    this.hasExhibitorsCache = true;
                    this.exhibitorsCacheDate = new Date();
                    return response.data;
                })
                .catch((err) => {
                    throw err;
                });
        }
    }

}

export default new AxiosHelper();
