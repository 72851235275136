import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import language_it from './assets/i18n/it.json';
import language_en from './assets/i18n/en.json';

i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false },  // React already does escaping
  defaultNS: 'common',
  load: 'all',
  fallbackLng: 'en', //default
  resources: {
    en: {
      common: language_en               // 'common' is our custom namespace
    },
    it: {
      common: language_it
    },
  },
});


ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
