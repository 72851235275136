import React, { useState, useEffect } from "react";
import iconClose from "../img/icon-close-white.svg";
import ReactPlayer from "react-player";

interface Props {
  videoUrl: string;
  visible: boolean;
  onClose?: () => void;
}

const VideoModal = (props: Props) => {
  const { videoUrl, visible, onClose } = props;

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (onClose) onClose();
    else {
      // setIsVisible(false)
    }
  };
  const logStartPlay = () => {
    console.log("STARTED PLAY");
  };
  const logStopPlay = () => {
    console.log("STOPPED PLAY");
  };
  const logPausePlay = () => {
    logStopPlay();
  };
  const logEndedPlay = () => {
    logStopPlay();
  };

  if (visible)
    return (
      <div className="video-modal-wrapper">
        <div className="modal-close" onClick={(event) => handleClose(event)}>
          <img src={iconClose} alt="Close" />
        </div>
        <div className="player-wrapper">
          <ReactPlayer
            url={videoUrl}
            playing={true}
            controls={true}
            onStart={logStartPlay}
            onPause={logPausePlay}
            onEnded={logEndedPlay}
            width="80%"
            height="80%"
          />
        </div>
      </div>
    );
  else return null;
};

export default VideoModal;
